// import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUsuarios } from './getUsuarios'
import Swal from 'sweetalert2'
import { handleResetPassword } from './restorePass'
import axios from 'axios'


export const UserScreen = () => {

  const {token} = useSelector(state => state.auth)


  const [usuarios, setUsuarios] = useState()
  

  useEffect(()=>{
    getUsuarios(setUsuarios)
  },[])

  const deleteUser = async(nombre,apellido , id)=>{
    Swal.fire({
      title: `Desea eliminar al usaurio ${nombre} ${apellido}`,
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const config = {headers:{"x-token":token}}
        await axios.delete(`https://apipwx.ovh/api/users/deletePermanently/${id}`,config)
        window.location.reload()
      }
    })

  }


  return (
    <div className='containerUser'>
      <h2>Administración de Usuarios</h2>
      <div className='BotonAdd'>
          <Link className='btnCrear' to='./altaUsuario'>Nuevo Usuario</Link>
      </div>

      <div className='tableContainer'>
          <table className='TablaUsuarios'>
            <thead className='EncabezadoUsuarios'>
              <tr>
                <th>Nombre</th>
                <th>correo</th>
                <th>Rol</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>  
            
            <tbody>
              {usuarios?.map((usuario)=>{
                return(
                  <tr className="filaUsuario" key={usuario._id}>
                  <td>{`${usuario.nombres} ${usuario.apellidos}`}</td>
                  <td>{usuario.correo}</td>
                  <td>{usuario.role}</td>
                  <td>{usuario.activo ? 'Si' : 'No'}</td>
                  <td className='botoneraUsuarios'>
                    <button className='btnActions'><span className="material-symbols-outlined" onClick={()=>{handleResetPassword(token,usuario)}}>lock_reset</span></button>
                    <Link to={`/usuarios/${usuario._id}`} className='btnActions'><span className="material-symbols-outlined">edit</span></Link>
                    <button className='btnActions'><span className="material-symbols-outlined" onClick={()=>{deleteUser(usuario.nombres,usuario.apellidos,usuario._id)}} >delete</span></button>
                  </td>
                </tr>
                )
              })}
            </tbody>          
          </table>
        
      </div>
    </div>
  
  )
}
