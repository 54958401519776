import axios from 'axios'
import { useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'


export const GaleriaScreen = () => {

    const {token} = useSelector(state => state.auth)
    const {idServicio} = useParams()
    const [images, setImages] = useState()


    useEffect(()=>{
        const getImages = async ()=>{
            const {data} = await axios.get(`https://apipwx.ovh/api/services/${idServicio}`)
            setImages(data.servicio.images)
        }
        getImages()
    },[idServicio])


    const deleteImagen = async(nombre,idImagen)=>{
        Swal.fire({
            title: `Desea eliminar la imágen ${nombre}`,
            text: "Esta acción es irreversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminar!'
            }).then(async(result) => {
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                await axios.delete(`https://apipwx.ovh/api/services/images/${idServicio}/${idImagen}`,config)
                window.location.reload()
            }
            })
        
        }

return (
    <div className='imagesContainer'>
        <div className='BotonAdd'>
            <Link className='btnCrear' to='./AddImages'>+ Imágenes</Link>
        </div>
        {images?.length > 0 ? 

            images?.map((imagen)=>{
                    return(
                        
                            <div className='cardImage' key={imagen?._id}>
                                <div className='ImageServiceCard'>
                                    <div className='nombreImagen'><h5>{imagen?.nombre}</h5></div>
                                    <img className='ImageService' src={imagen?.url} alt={imagen?.nombre}></img>
                                </div>
                                <div className='accionesImages'>

                                    <button className='btnImage' onClick={(e)=>deleteImagen(imagen?.nombre,imagen?._id)}><span className="material-symbols-outlined">delete</span></button>
                                </div>
                            </div>
                    )
                })
        
        : <p>No hay imágenes que mostrar</p>}



    </div>
)
}
