import React from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

export const ConfirmarCuenta = () => {

  const {id} = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    const activarUsuario = async()=>{
      await axios.put(`https://apipwx.ovh/api/users/activate/${id}`)
    }
    
    activarUsuario()
    Swal.fire({
      text:"Usuario Activado con exito",
      toast:true
    })
    navigate('/Usuarios')    
},[id,navigate])    
    
  return (
    <div></div>
  )
}
