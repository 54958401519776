import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getUser } from './getUser'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const OneUserScreen = () => {

    const {id} = useParams()
    const [usuario,setUsuario] = useState({})
    const {token} = useSelector(state => state.auth)
    const navigate = useNavigate()
    
    
    useEffect(()=>{
        getUser(id,setUsuario)
    },[id])

    const handleInput = (e)=>{
        e.preventDefault()        
        setUsuario({...usuario,[e.target.name]:e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        

        Swal.fire({
            title: '¿Quieres guardar los cambios?',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Guardar',
            denyButtonText: `No guardar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.put(`https://apipwx.ovh/api/users/${id}`,{role:usuario.role,activo:usuario.activo},config)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Usuarios')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
        
    }

    return (
        <>
            <div className='containUser'>
                <h2>Editar a {`${usuario?.nombres} ${usuario?.apellidos}`}</h2>        
            
            <div>
                <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                        <label htmlFor='nombres'>Nombres:</label>
                        <input type="text" onChange={e=>handleInput(e)}  name="nombres" className="input-formUser" placeholder={usuario?.nombres} disabled/>  
                        <label htmlFor='apellidos'>Apellidos:</label>
                        <input type="text" onChange={e=>handleInput(e)}  name="apellidos" className="input-formUser" placeholder={usuario?.apellidos} disabled/>   
                        <label htmlFor='correo'>Email:</label>                   
                        <input type="email" onChange={e=>handleInput(e)}  name="correo" className="input-formUser" placeholder={usuario?.correo} disabled/>
                        <label htmlFor='role'>Rol de usuario:</label> 
                        <select  className="selectFormUser" onChange={e=>handleInput(e)} name="role" value={usuario?.role}>
                            <option value="ADMIN">ADMIN</option>
                            <option value="EDITOR">EDITOR</option>
                        </select>
                        <label htmlFor='activo'>Estado:</label> 
                        <select  className="selectFormUser" onChange={e=>handleInput(e)} name="activo" value={usuario?.activo}>
                            <option value={true}>Sí</option>
                            <option value={false}>No</option>
                        </select>
                        <button className="btnUser"> Guardar </button>
                        <Link className="btnAtrasUser" to="/Usuarios">Atras</Link>
                </form>
            </div>
            </div>
        </>
    )
}
