import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    token:'',
    usuario:null,
}

export const authSlice = createSlice({
    name:'user',
    initialState,
    reducers: {
        login: (state,action)=>{
            const {token,usuario} = action.payload
            state.token = token
            state.usuario = usuario        
        },
        logout: (state,action)=>{
            state.token = ''
            state.usuario = null
        }
    }
})

export const {login,logout,checkCredentials} = authSlice.actions