import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const NuevoBanner = () => {


    const [banner, setBanner] = useState({})
    const navigate = useNavigate()
    const {token} = useSelector(state => state.auth)

    const handleInput = (e)=>{
        e.preventDefault()
        setBanner({...banner,[e.target.name]:e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        Swal.fire({
            title: `¿Quieres crear el banner ${banner.titulo}` ,
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Crear Banner',
            denyButtonText: `Descartar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.post('https://apipwx.ovh/api/banners',banner,config)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Banners')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
    }


    return (
        <div className='containUser'>
        <h2>Alta nuevo Banner</h2>        
    
    <div>
        <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                <label htmlFor='titulo'>Titulo:</label>
                <input type="text" onChange={e=>handleInput(e)}  name="titulo" className="input-formUser" placeholder='Titulo'/>  
                <label htmlFor='position'>Posición:</label>
                <input type="number" onChange={e=>handleInput(e)}  name="position" className="input-formUser" placeholder='Posición Ej: 1' />   
                <label htmlFor='url'>URL:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="url" className="input-formUser" placeholder='Url de cloudinary' />
                <label htmlFor='link'>Link:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="link" className="input-formUser" placeholder='Link a donde redirige' />              
                <label htmlFor='activo'>Estado:</label> 
                <select  className="selectFormUser" onChange={e=>handleInput(e)} name="activo" defaultValue={true}>
                    <option value={true}>Sí</option>
                    <option value={false}>No</option>
                </select>
                <button className="btnUser"> Guardar </button>  
                <Link className="btnAtrasUser" to="/Banners">Atrás</Link>
        </form>
    </div>
    </div>
    )
}
