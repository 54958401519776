import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { logout } from '../../../store/auth/authSlice'

export const Menu = () => {

    const Swal = require('sweetalert2')


    const {usuario} = useSelector(state => state.auth)
    const [activo, setActivo] = useState(false)
    const contenedorMenu = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(()=>{
        const menu = contenedorMenu.current
        if(!activo){
            menu.classList.add('desactivado')
        }else{
            menu.classList.remove('desactivado')
        }
    },[activo])



    const handleMenu = ()=>{
        setActivo(!activo)
    }

    const handleLogOut = ()=>{            
            Swal.fire({
                title: '¿Desea Cerrar sesión?',
                text: "Recuerda validar si has guardado los todos los cambios",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'si, cerrar sesión',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.localStorage.removeItem('token')
                    window.localStorage.removeItem('usuario')
                    dispatch(logout())
                    navigate('/login')
                }
            })
    }


    return (
        <>
        <div className='headerMenu'>
            <Link to="/">
                <img className='logoHeaderMenu' src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1673286079/imagenes/Miscelaneos/Home/logo_u6cmbw.webp" alt="Proworx Logo"/>
            </Link>
            <a className="previewPage" href='https://www.proworxby420.com/' target='_blank' rel="noreferrer"><span className="material-symbols-outlined">visibility</span></a>
        </div>
        <nav className="contenedorMenu" ref={contenedorMenu}>

            {activo ? 
                    <div className='saludoMenu'>
                        <h4>Hola</h4>
                        <p>BIENVENIDO</p>
                        <p><b>{usuario?.nombres} {usuario?.apellidos}</b></p>
                    </div>
                    
                    :
                    <div className='saludoMenuIniciales'>
                        <h5>Hola!</h5>
                        <div className='iniciales'>
                            <p>{usuario?.nombres[0]} {usuario?.apellidos[0]}</p>
                        </div>
                    </div>
            }
            <div className='separador'></div>
            {activo ? 
                <div className='ListaMenu'>
                    {usuario?.role === 'ADMIN' ?
                    <ul>
                        <li><NavLink className={({isActive})=> `linkMenu ${isActive ? 'LinkActivo' : ""}`} to="/Servicios"><span className="material-symbols-outlined">settings_suggest</span>&nbsp; Servicios</NavLink></li>
                        <li><NavLink className={({isActive})=> `linkMenu ${isActive ? 'LinkActivo' : ""}`} to="/Banners"><span className="material-symbols-outlined">photo_library</span>&nbsp; Banners</NavLink></li>
                        <li><NavLink className={({isActive})=> `linkMenu ${isActive ? 'LinkActivo' : ""}`} to="/Usuarios"><span className="material-symbols-outlined">group_add</span>&nbsp; Usuarios</NavLink></li>
                    </ul>
                    :
                    <ul>
                        <li><NavLink className={({isActive})=> `linkMenu ${isActive ? 'LinkActivo' : ""}`} to="Servicios"><span className="material-symbols-outlined">settings_suggest</span>&nbsp; Servicios</NavLink></li>
                        <li><NavLink className={({isActive})=> `linkMenu ${isActive ? 'LinkActivo' : ""}`} to="Banners"><span className="material-symbols-outlined">photo_library</span>&nbsp; Banners</NavLink></li>
                    </ul>
                    }
                </div>
            
                    :   
                    <div className='ListaMenuIniciales'>
                        {usuario?.role === 'ADMIN' ?
                        <ul>
                            <li><NavLink className={({isActive})=> `linkMenu LinkMenuInicial ${isActive ? 'LinkActivo' : ""}`} to="Servicios"><span className="material-symbols-outlined">settings_suggest</span></NavLink></li>
                            <li><NavLink className={({isActive})=> `linkMenu LinkMenuInicial ${isActive ? 'LinkActivo' : ""}`} to="Banners"><span className="material-symbols-outlined">photo_library</span></NavLink></li>
                            <li><NavLink className={({isActive})=> `linkMenu LinkMenuInicial ${isActive ? 'LinkActivo' : ""}`} to="Usuarios"><span className="material-symbols-outlined">group_add</span></NavLink></li>
                        </ul>
                        :
                        <ul>
                            <li><NavLink className={({isActive})=> `linkMenu LinkMenuInicial ${isActive ? 'LinkActivo' : ""}`} to="Servicios"><span className="material-symbols-outlined">settings_suggest</span></NavLink></li>
                            <li><NavLink className={({isActive})=> `linkMenu LinkMenuInicial ${isActive ? 'LinkActivo' : ""}`} to="Banners"><span className="material-symbols-outlined">photo_library</span></NavLink></li>
                        </ul>
                    }
                    </div>
            }
            
            {
                activo ?
                        <div className='logoutContainer'>
                            <button onClick={handleLogOut} className="btnLogOut"><span className="material-symbols-outlined">logout</span>Salir</button>
                        </div>
                        :
                        <div className='logoutContainerIniciales'>
                            <button onClick={handleLogOut} className="btnLogOut iniciales"><span className="material-symbols-outlined">logout</span></button>    
                        </div>
            }        

            <button onClick={handleMenu} className='btnMenu'>
                {!activo ? <span className="material-symbols-outlined">menu</span> 
                        : <span className="material-symbols-outlined">close</span>}
            </button>
        </nav>
        </>
    )
}
