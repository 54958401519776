import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'

export const NuevoUsuario = () => {

    const [usuario,setUsuario] = useState({})
    const navigate = useNavigate()


    const handleInput = (e)=>{
        e.preventDefault()        
        setUsuario({...usuario,[e.target.name]:e.target.value})
    }




    

    const handleSubmit = async(e)=>{
        e.preventDefault()
        Swal.fire({
            title: `¿Quieres crear el usuario ${usuario.nombres} ${usuario.apellidos}` ,
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Crear Usuario',
            denyButtonText: `Descartar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log(usuario)
                const {data} = await axios.post('https://apipwx.ovh/api/users',usuario)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Usuarios')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })

    }



return (
    <>
    <div className='containUser'>
        <h2>Nuevo Usuario</h2>
        <small>No tienes correo para este usuario, da <a href='https://www.proworxby420.com:2083/' target='_blank' rel='noreferrer'>click aquí</a></small>       
    
    <div>
        <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                <label htmlFor='nombres'>Nombres:</label>
                <input type="text" onChange={e=>handleInput(e)}  name="nombres" className="input-formUser" placeholder="Nombres" />  
                <label htmlFor='apellidos'>Apellidos:</label>
                <input type="text" onChange={e=>handleInput(e)}  name="apellidos" className="input-formUser" placeholder="Apellidos" />   
                <label htmlFor='correo'>Email:</label>                   
                <input type="email" onChange={e=>handleInput(e)}  name="correo" className="input-formUser" placeholder="Correo Electronico" />
                <label htmlFor='password'>Contraseña:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="password" className="input-formUser" placeholder="Contraseña" />
                <label htmlFor='role'>Rol de usuario:</label> 
                <select  className="selectFormUser" onChange={e=>handleInput(e)} name="role" value={usuario?.role}>
                    <option value="ADMIN">ADMIN</option>
                    <option value="EDITOR">EDITOR</option>
                </select>
                <label htmlFor='activo'>Estado:</label> 
                <button className="btnUser"> Guardar </button>
                <Link className="btnAtrasUser" to="/Usuarios">Atras</Link>
        </form>
    </div>
    </div>
</>
)
}
