import React from 'react'
import { useSelector } from 'react-redux'

export const PaginaPrincipal = () => {
    const {usuario} = useSelector(state => state.auth)

    return (
        <>
        <section className='contenedorPaginaInicial'>
            <div className='paginaBienvenida'>
                <h1>Bienvenido al administrador de Proworx</h1>
                <h3>{usuario?.nombres} {usuario?.apellidos}</h3>
            </div>
            <div>
                <div className='divVideo'>
                    <video className='video' autoPlay loop muted>
                        <source src="https://res.cloudinary.com/dyikrt3cc/video/upload/v1673470442/videos/Miscelaneos/Backoffice/Veh%C3%83_culo_-_25564_wrr3bx.mp4" type="video/mp4"/>
                    </video>
                </div>
            </div>  
        </section>          
        </>
        
    )
}
