import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Login } from '../components/Login/Login'
import { login } from '../store/auth/authSlice'
import { PageRouters } from './PageRouters'

export const AppRouter = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
    const usuario = JSON.parse(window.localStorage.getItem('usuario'))
    const token = window.localStorage.getItem('token')
    usuario ? 
      dispatch(login({usuario,token}))
      :
      navigate('/login')
  },[dispatch,navigate])

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login/>} />
        <Route path='*' element={<PageRouters/>}/>
      </Routes>
    </>
  )
}
