import React from 'react'
import {  Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'


export const UpdateBanner = () => {

    const {id} = useParams()
    const {token} = useSelector(state => state.auth)
    const [banner, setBanner] = useState({})
    const navigate = useNavigate()

    useEffect(()=>{
        const getBanner = async()=>{
            const {data} = await axios.get(`https://apipwx.ovh/api/banners/${id}`)
            setBanner(data.banner)
        }
        getBanner()
    },[id])

    const handleInput = (e)=>{
        e.preventDefault()
        setBanner({...banner,[e.target.name]:e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        console.log(banner)
        

        Swal.fire({
            title: '¿Quieres guardar los cambios?',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Guardar',
            denyButtonText: `No guardar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.put(`https://apipwx.ovh/api/banners/${id}`,banner,config)
                console.log(data)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Banners')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
        
    }

    

    return (
        <div className='containUser'>
        <h2>Editar Banner {`${banner?.titulo}`}</h2>        
    
    <div>
        <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                <label htmlFor='titulo'>Titulo:</label>
                <input type="text" onChange={e=>handleInput(e)}  name="titulo" className="input-formUser" placeholder={banner?.titulo} disabled/>  
                <label htmlFor='position'>Posición:</label>
                <input type="number" onChange={e=>handleInput(e)}  name="position" className="input-formUser" placeholder={banner?.position} />   
                <label htmlFor='url'>URL:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="url" className="input-formUser" placeholder={banner?.url} />
                <label htmlFor='link'>Link:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="link" className="input-formUser" placeholder={banner?.link} />              
                <label htmlFor='activo'>Estado:</label> 
                <select  className="selectFormUser" onChange={e=>handleInput(e)} name="activo" value={banner?.activo}>
                    <option value={true}>Sí</option>
                    <option value={false}>No</option>
                </select>
                <button className="btnUser"> Guardar </button>  
                <Link className="btnAtrasUser" to="/Banners">Atras</Link>
        </form>
    </div>
    </div>
    )
}
