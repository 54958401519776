import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { BannerScreen } from '../components/Banners/BannerScreen'
import { Menu } from '../components/Elementos/menu/Menu'
import { PaginaPrincipal } from '../components/PaginaPrincipal/PaginaPrincipal'
import { ServiciosScreen } from '../components/Servicios/ServiciosScreen'
import { NuevoUsuario } from '../components/Usuarios/NuevoUsuario'
import { ConfirmarCuenta } from '../components/Usuarios/ConfirmarCuenta'
import { OneUserScreen } from '../components/Usuarios/OneUserScreen'
import { UserScreen } from '../components/Usuarios/UserScreen'
import { UpdateBanner } from '../components/Banners/UpdateBanner'
import { NuevoBanner } from '../components/Banners/NuevoBanner'
import { UpdateServicio } from '../components/Servicios/UpdateServicio'
import { NuevoServicio } from '../components/Servicios/NuevoServicio'
import { GaleriaScreen } from '../components/Servicios/galeria/GaleriaScreen'
import { AddImages } from '../components/Servicios/galeria/AddImages'


export const PageRouters = () => {


    return (
        <>
        <Menu/>
        <div className='paginasAdmin'>
        <Routes>
            <Route path='/' element={<PaginaPrincipal/>}/>
            <Route path='/Usuarios' element={<UserScreen/>}/>
            <Route path='/Usuarios/:id' element={<OneUserScreen/>}/>
            <Route path='/Usuarios/altaUsuario' element={<NuevoUsuario/>}/>
            <Route path='/confirmarCuenta/:id' element={<ConfirmarCuenta/>}/>            
            <Route path='/Servicios' element = {<ServiciosScreen/>}/>
            <Route path='/Servicios/crearServicio' element = {<NuevoServicio/>}/>
            <Route path='/Servicios/:id' element = {<UpdateServicio/>}/>
            <Route path='/Servicios/Galeria/:idServicio' element = {<GaleriaScreen/>}/>
            <Route path='/Servicios/Galeria/:idServicio/AddImages' element = {<AddImages/>}/>
            <Route path='/Banners' element = {<BannerScreen/>}/>
            <Route path='/Banners/crearBanner' element = {<NuevoBanner/>}/>            
            <Route path='/Banners/:id' element = {<UpdateBanner/>}/>
        </Routes>
        </div>
        </>
    )
}
