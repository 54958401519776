import axios from 'axios'
import Swal from 'sweetalert2'



export const handleResetPassword = async(token,usuario)=>{
    Swal.fire({
    title: `Restaurar contraseña de ${usuario?.nombres} ${usuario?.apellidos}`,
    text: "Esta seguro que desea restaurar la contraseña",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Sí, Cambiar la contraseña'
    }).then((result) => {
    if (result.isConfirmed) {
        Swal.fire({
            input: 'text',
            inputAttributes: {
            title: 'Ingrese la nueva contraseña',
            autocapitalize: 'off',
            placeholder:'Nuevo Password'

        },
            showCancelButton: true,
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,
            preConfirm: async(newPassword) => {
            const config = {headers:{"x-token":token}}
            try {
                await axios.put(`https://apipwx.ovh/api/users/restorePass/${usuario?._id}`,{password:newPassword},config)
                Swal.fire({
                icon:'success',
                text: `La contraseña se ha restaurado correctamente`,
                })
            } catch (error) {
            Swal.fire({
                icon:'error',
                text: `${error.response.data.errors[0].msg}`,
            })
            }
        },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }
    })    
}