import React from 'react'


export const Input = (params) => {

return(
    <div className="form__group field">
    <input onChange={(e)=> params.callback(e)} name={params.nombre} required="" placeholder={params.placeholder} className="form__field" type={params.tipo}/>
    <label className="form__label" htmlFor={params.id}>{params.label}</label>
</div>
)
}
