import {React, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const NuevoServicio = () => {

    const navigate = useNavigate()
    const {token} = useSelector(state => state.auth)
    const [servicio, setServicio] = useState({})
    const handleInput = (e)=>{
        e.preventDefault()
        setServicio({...servicio,[e.target.name]:e.target.value})
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        Swal.fire({
            title: `¿Quieres crear el servicio ${servicio.nombre}` ,
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Crear Servicio',
            denyButtonText: `Descartar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.post('https://apipwx.ovh/api/services',servicio,config)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Servicios')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
    }



    return (
            <div className='containUser servicios'>
            <h2>Alta nuevo Servicio</h2>
            <small><i>*Nota: La galería de imágenes se configura después de dado de alta el servicio</i></small>
            <div>
            <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                    <label htmlFor='nombre'>Titulo:</label>
                    <input type="text" onChange={e=>handleInput(e)}  name="nombre" className="input-formUser" placeholder="Nombre servicio" />  
                    <label htmlFor='description'>Descripción:</label>
                    <textarea className='textAreaService' placeholder="Descripción" name="description"  onChange={e=>handleInput(e)}></textarea>
                    <label htmlFor='miniatura'>Miniatura:</label>                   
                    <input type="text" onChange={e=>handleInput(e)}  name="miniatura" className="input-formUser" placeholder="url imagen miniatura" />
                    <label htmlFor='video'>Video:</label>                      
                    <input type="text" onChange={e=>handleInput(e)}  name="video"  className="input-formUser" placeholder="url video"/>          
                    <label htmlFor='activo'>Estado:</label> 
                    <select  className="selectFormUser" onChange={e=>handleInput(e)} name="activo" value={true}>
                        <option value={true}>Sí</option>
                        <option value={false}>No</option>
                    </select>
                    <button className="btnUser"> Guardar </button>  
                    <Link className="btnAtrasUser" to="/Servicios">Atras</Link>
            </form>
        </div>
        </div>
    )
}
