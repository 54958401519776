import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'


export const UpdateServicio = () => {

    const {id} = useParams()
    const {token} = useSelector(state => state.auth)
    const [servicio, setServicio] = useState({})
    const navigate = useNavigate()


    useEffect(()=>{
        const getServicio = async()=>{
            const {data} = await axios.get(`https://apipwx.ovh/api/services/${id}`)
            setServicio(data.servicio)
        }
        getServicio()
    },[id])



    const handleInput = (e)=>{
        e.preventDefault()
        setServicio({...servicio,[e.target.name]:e.target.value})
    }
    
    const handleSubmit = async(e)=>{
        e.preventDefault()
        console.log(servicio)
        

        Swal.fire({
            title: '¿Quieres guardar los cambios?',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Guardar',
            denyButtonText: `No guardar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.put(`https://apipwx.ovh/api/services/${id}`,servicio,config)
                console.log(data)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Servicios')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
        
    }





    return (
        <div className='containUser servicios'>
        <h2>Editar servicio {`${servicio?.nombre}`}</h2>
        <div>
        <form className='formUser' onSubmit={e=>handleSubmit(e)}>
                <label htmlFor='nombre'>Titulo:</label>
                <input type="text" onChange={e=>handleInput(e)}  name="nombre" className="input-formUser" placeholder={servicio?.nombre} />  
                <label htmlFor='description'>Descripción:</label>
                <textarea className='textAreaService' placeholder={servicio?.description} name="description"  onChange={e=>handleInput(e)}></textarea>
                <label htmlFor='miniatura'>Miniatura:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="miniatura" className="input-formUser" placeholder={servicio?.miniatura} />
                <label htmlFor='video'>Video:</label>                      
                <input type="text" onChange={e=>handleInput(e)}  name="video"  className="input-formUser" placeholder={servicio?.video} />          
                <label htmlFor='activo'>Estado:</label> 
                <select  className="selectFormUser" onChange={e=>handleInput(e)} name="activo" value={servicio?.activo}>
                    <option value={true}>Sí</option>
                    <option value={false}>No</option>
                </select>
                <button className="btnUser"> Guardar </button>  
                <Link className="btnAtrasUser" to="/Servicios">Atras</Link>
        </form>
    </div>
    </div>
    )
}
