import {React, useState} from 'react'
import { Input } from '../Elementos/Login/Input'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { login } from '../../store/auth/authSlice'
import { useNavigate } from 'react-router-dom'


export const Login = () => {

  const [data, setData] = useState({})
  const [errores, setErrores] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleInput = (e) =>{
    setData({...data ,[e.target.name] : e.target.value})      
  }

  const handleLogin = async(e)=>{
    e.preventDefault()
    setLoading(true)

    try {
        
      if(!data.correo || !data.password){
        setErrores('El correo y la contraseña son obligatorios')
        setLoading(false)
        console.log(errores)
        return
      }   

      const peticion = await axios.post('https://apipwx.ovh/api/auth/login',data)
      const {token, usuario} = await peticion.data
      dispatch(login({
        token,
        usuario
      }))

      window.localStorage.setItem('token',token)
      window.localStorage.setItem('usuario',JSON.stringify(usuario))

      setLoading(false)
      navigate('/')

    } catch (error) {
            setErrores(error.response.data.msg)
            setLoading(false)
    }      
  }

  return (
    <>
      <div className='loginContainer'>
      <div className='logoImage'>
        <img src="https://res.cloudinary.com/dyikrt3cc/image/upload/v1673286079/imagenes/Miscelaneos/Home/logo_u6cmbw.webp" alt="Proworx" />
      </div>
      <div className="card">
        <div className='formLogin'>
            <form onSubmit={handleLogin}>
              <Input callback={handleInput} nombre = "correo" label="Correo Electrónico" placeholder="correo Electrónico" id="email" tipo="Text"/>
              <Input callback={handleInput} nombre = "password" label="Password" placeholder="Password" id="password" tipo="Password"/>
              {
                !loading ?
                <button className="btn">Iniciar Sesión</button> :

                <div className="spinner">
                    <div className="spinner1"></div>
                </div>
              }    

              {errores && <p className='error'>{errores}</p>}          
            </form>
        </div>
      </div>
    </div>
    </>
  )
}
