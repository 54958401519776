import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { getBanners } from './getBanners'

export const BannerScreen = () => {
  
  const {token} = useSelector(state => state.auth)
  const [banners, setBanners] = useState()


  useEffect(()=>{
    getBanners(setBanners)
  },[])

  const deleteBanner = async(titulo , id)=>{
    Swal.fire({
      title: `Desea eliminar el banner ${titulo}`,
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const config = {headers:{"x-token":token}}
        await axios.delete(`https://apipwx.ovh/api/banners/${id}`,config)
        window.location.reload()
      }
    })

  }



  return (
    <div className='containerUser'>
      <h2>Administración de Banners</h2>
      <div className='BotonAdd'>
          <Link className='btnCrear' to='./crearBanner'>Nuevo Banner</Link>
      </div>

      <div className='tableContainer'>
        
          <table className='TablaUsuarios'>
            <thead className='EncabezadoUsuarios'>
              <tr>
                <th>Miniatura</th>
                <th>Titulo</th>
                <th>Posición</th>
                <th>Url Imagen</th>
                <th>Link</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>  
            
            <tbody>
              {banners?.map((banner)=>{
                return(
                  <tr className="filaUsuario" key={banner._id}>
                  <td><a href={banner.url} target="_blank" rel='noreferrer'><img className='miniaturaImg' src={banner.url} alt={banner.titulo}/></a></td>
                  <td>{`${banner.titulo}`}</td>
                  <td>{banner.position}</td>
                  <td>{banner.url}</td>
                  <td>{banner.Link}</td>
                  <td>{banner.activo ? 'Si' : 'No'}</td>
                  <td className='botoneraUsuarios'>                    
                    <Link to={`/Banners/${banner._id}`} className='btnActions'><span className="material-symbols-outlined">edit</span></Link>
                    <button className='btnActions'><span className="material-symbols-outlined" onClick={e => deleteBanner(banner.titulo, banner._id)}>delete</span></button>
                  </td>
                </tr>
                )
              })}
            </tbody>          
          </table>
        
      </div>
    </div>
  ) 
}

