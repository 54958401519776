import React ,{useState}from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const AddImages = () => {

    const {token} = useSelector(state => state.auth)
    const [imagen, setImagen] = useState({})
    const [imagenes, setImagenes] = useState([])
    const {idServicio} = useParams()
    const navigate = useNavigate()

    const handleInput = (e)=>{
        e.preventDefault()
        setImagen({...imagen,[e.target.name]:e.target.value})
    }

    const handleAdd = ()=>{
        setImagenes([...imagenes,imagen])
        console.log(idServicio)
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        handleAdd()
        console.log(imagenes)
        Swal.fire({
            title: `Desea cargar las imagenes en la lista?` ,
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: 'Cargar',
            denyButtonText: `Descartar`,
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const config = {headers:{"x-token":token}}
                const {data} = await axios.post(`https://apipwx.ovh/api/services/Images/${idServicio}`,imagenes,config)
                Swal.fire({
                    text:data.msg,
                    toast:true,
                    timer:1000,
                    timerProgressBar:true
                })
                navigate('/Servicios')
                
            } else if (result.isDenied) {
                Swal.fire('Los cambios no fueron guardados', '', 'info')
                
            }
        })
    }

    


    return (
        <div className='containUser servicios'>
        <h2>Añadir Imágenes</h2>
        <small><i>*Nota: Para agregar multiples imagenes da click en agregar otra imagen y vuelve a llenar el formulario</i></small>
        <button className="Lista" onClick={handleAdd}> Agregar imagen a la lista </button>
        <div>
        
        
        <form className='formUser' onSubmit={e=>handleSubmit(e)}>
            
                <label htmlFor='miniatura'>Nombre:</label>                   
                <input type="text" onChange={e=>handleInput(e)}  name="nombre" className="input-formUser" placeholder="nombre de la imagen" />
                <label htmlFor='video'>URL:</label>                      
                <input type="text" onChange={e=>handleInput(e)}  name="url"  className="input-formUser" placeholder="url video"/> 

                {imagenes.length > 0 ? 
                <>
                <h4>Lista de imágenes cargadas</h4>
                <ul>
                    {imagenes.map((img)=>{

                        return <li key={Math.random()*1000000}>
                            {img.nombre}</li>
                    })}
                </ul>
                </>
                :
                <p>Aún no se han cargado imagenes</p>
                }

                
                <button className="btnUser"> Guardar </button>  
                <Link className="btnAtrasUser" to="/Servicios">Atras</Link>
        </form>
        
    </div>
    </div>
    )
}
