import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getServicios } from './getServicios'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const ServiciosScreen = () => {

  const {token} = useSelector(state => state.auth)
  const [servicios, setServicios] = useState()

  useEffect(()=>{
    getServicios(setServicios)
  },[])


  const deleteServicio = async(nombre,id)=>{
    Swal.fire({
      title: `Desea eliminar el Servicio ${nombre}`,
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const config = {headers:{"x-token":token}}
        await axios.delete(`https://apipwx.ovh/api/services/${id}`,config)
        window.location.reload()
      }
    })

  }



  return (
    <div className='containerUser'>
      <h2>Administración de Servicios</h2>
      <div className='BotonAdd'>
          <Link className='btnCrear' to='./crearServicio'>Nuevo Servicio</Link>
      </div>

      <div className='tableContainer'>
        
          <table className='TablaUsuarios'>
            <thead className='EncabezadoUsuarios'>
              <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Miniatura</th>
                <th>Video</th>
                <th>Galería</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>  
            
            <tbody>
              {servicios?.map((servicio)=>{
                return(
                  <tr className="filaUsuario" key={servicio._id}>                  
                  <td>{`${servicio.nombre}`}</td>
                  <td className='tdDescription'>{`${servicio.description.substring(0,200)}...`}</td>
                  <td className='tdURL'><img src={servicio.miniatura} alt={servicio.nombre}/></td>
                  <td className='tdURL'>
                  <video className='videoAdmin' controls={true} >
                    <source src={servicio.video} type="video/mp4"/>
                  </video>
                  </td>
                  <td><Link to={`./Galeria/${servicio._id}`}className='btnGaleria'>Ver Galería</Link></td>
                  <td>{servicio.activo ? 'Si' : 'No'}</td>
                  <td className='botoneraServicios'>                    
                    <Link to={`/Servicios/${servicio._id}`} className='btnActions'><span className="material-symbols-outlined">edit</span></Link>
                    <button className='btnActions'><span className="material-symbols-outlined" onClick={e => deleteServicio(servicio.nombre, servicio._id)}>delete</span></button>
                  </td>
                </tr>
                )
              })}
            </tbody>          
          </table>
        
      </div>
    </div>
  )
}
